<template>
  <div>
    <t-modal v-model="editInvoice">
      <t-modal v-model="deleteInvoiceDialog">
        <div class="p-4">
          <h4 class="text-sm font-semibold text-gray-800">Delete Invoice</h4>
          <h4 class="text-base mt-4 text-red-500">
            You are about to delete this invoice including all payment made for
            this invoice are you sure?
          </h4>
          <p></p>
          <div class="flex mt-5 w-full justify-between items-center">
            <div>
              <t-button
                @click="deleteInvoice(selectedClient._id)"
                class="flex space-x-3 items-center border w-28 border-accent px-3 py-1 rounded text-accent"
                variant="error"
                ><span>Delete</span>
                <spinner v-if="deletingInvoice"></spinner>
              </t-button>
            </div>
          </div>
        </div>
      </t-modal>
      <t-modal v-model="editInvoiceDialog">
        <div class="p-4">
          <div class="bg-white p-5">
            <h4 class="text-sm font-semibold text-gray-800 mb-4">Invoice</h4>
            <div class="flex flex-col">
              <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="form-control">
                  <label
                    class="text-xs font-semibold text-gray-800 mb-2 block"
                    for="name"
                    >Invoice Title</label
                  >
                  <t-input
                    class="focus:outline-none"
                    type="text"
                    v-model="selectedClient.title"
                    aria-autocomplete="no"
                  ></t-input>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Amount</label
                    >
                    <t-input
                      class="focus:outline-none"
                      type="number"
                      v-model="selectedClient.amount"
                      aria-autocomplete="no"
                    ></t-input>
                  </div>
                </div>
              </form>
              <div class="flex space-x-5 items-center justify-end mt-5">
                <t-button
                  @click="editInvoiceDialog = false"
                  class="border w-28 border-accent px-3 py-1 rounded text-accent"
                  variant="error"
                >
                  Cancel
                </t-button>
                <t-button
                  @click="editAnInvoice"
                  class="w-28 flex items-center justify-center"
                >
                  <span v-if="!editingInvoice">Save</span>
                  <spinner v-else></spinner>
                </t-button>
              </div>
            </div>
          </div>
        </div>
      </t-modal>
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Invoice</h4>
        <div>
          <div>
            <page-title>Jonathan Kings</page-title>
            <page-subtitle>Payment Invoice</page-subtitle>
            <div class="grid grid-cols-2 gap-5 mt-5">
              <div>
                <p class="text-sm text-black font-semibold">Client's Name</p>
                <p class="text-base">
                  {{
                    selectedClient.client !== null
                      ? selectedClient.client.firstName
                      : 'Account deleted'
                  }}
                  {{
                    selectedClient.client !== null
                      ? selectedClient.client.lastName
                      : ''
                  }}
                </p>
              </div>
              <div>
                <p class="text-sm text-black font-semibold">Amount To Pay</p>
                <p class="text-base">
                  {{ numberWithCommas(selectedClient.amount) }}
                </p>
              </div>
              <div>
                <p class="text-sm text-black font-semibold">Amount Paid</p>
                <p class="text-base">
                  {{ numberWithCommas(selectedClient.totalAmountPaid) }}
                </p>
              </div>
              <div>
                <p class="text-sm text-black font-semibold">Balance</p>
                <p class="text-base">
                  {{
                    numberWithCommas(
                      Number(selectedClient.amount) -
                        Number(selectedClient.totalAmountPaid)
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10 space-x-4 flex items-end justify-end">
          <t-button
            @click="deleteInvoiceDialog = true"
            variant="error"
            class="flex items-center text-sm justify-center border focus:outline-none border-accent px-3 py-1 rounded text-accent"
          >
            Delete Invoice
          </t-button>
          <t-button
            @click="editInvoiceDialog = true"
            class="flex text-sm items-center justify-center"
          >
            Edit Invoice
          </t-button>

          <t-button
            @click="printWork"
            class="flex text-sm items-center justify-center"
          >
            Print Invoice
          </t-button>
          <t-button
            :to="{
              name: 'PaymentList',
              params: { invoiceId: selectedClient._id }
            }"
            class="flex text-sm items-center justify-center"
          >
            Add Payment
          </t-button>
        </div>
      </div>
    </t-modal>
    <page-title>Invoice</page-title>
    <page-subtitle>Here's the list of Invoice</page-subtitle>

    <div id="printDocs" class="px-7 py-5 hidden">
      <div class="flex items-start justify-between">
        <div class="flex space-x-3 items-center">
          <img class="w-6 h-6" src="../../assets/logo.png" alt="" />
          <p class="text-sm font-semibold">Jonathan Kings</p>
        </div>
        <p class="font-light text-4xl">INVOICE</p>
      </div>
      <div class="flex mt-10 justify-between items-center">
        <div>
          <p class="text-sm text-gray-600">Bill To:</p>
          <p class="text-base font-bold">
            {{
              selectedClient.client !== null
                ? selectedClient.client.firstName
                : ''
            }}
            {{
              selectedClient.client !== null
                ? selectedClient.client.lastName
                : ''
            }}
          </p>
        </div>
        <div>
          <p class="flex items-center justify-between space-x-16">
            <span class="text-sm font-light text-gray-400">Date:</span>
            <span class="text-sm font-semibold">{{
              new Date().toDateString()
            }}</span>
          </p>
          <p class="flex items-center justify-between space-x-16">
            <span class="text-sm font-light text-gray-400">Balance Due:</span>
            <span class="text-sm font-semibold"
              >₦{{
                numberWithCommas(
                  Number(selectedClient.amount) -
                    Number(selectedClient.totalAmountPaid)
                )
              }}</span
            >
          </p>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex justify-evenly">
          <div>
            <h5 class="text-sm text-gray-500">Amount To Pay</h5>
            <p class="text-base font-semibold">
              ₦ {{ numberWithCommas(selectedClient.amount) }}
            </p>
          </div>
          <div>
            <h5 class="text-sm text-gray-500">Total Amount Paid</h5>
            <p class="text-base font-semibold">
              ₦{{ numberWithCommas(selectedClient.totalAmountPaid) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="md:flex rounded md:space-x-2 items-center justify-between">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            v-model="search"
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
        <t-button
          :to="{ name: 'CreateInvoice' }"
          class="text-sm block w-full mt-2 md:mt-0 md:w-44 py-2"
          >Create Invoice</t-button
        >
      </div>
      <div class="bg-white mt-10 grid p-5 rounded">
        <div class="mb-5 bg-white">
          <!-- <div
            class="tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"
          >
            <span
              class="py-3 px-5 cursor-pointer bg-primary text-white rounded-tl"
              >All Status</span
            >
            <span class="py-3 px-5 cursor-pointer">Draft</span>
            <span class="py-3 px-5 cursor-pointer">Pending</span>
            <span class="py-3 px-5 cursor-pointer">Cancelled</span>
          </div> -->
          <div v-if="loading" class="flex items-center justify-center mt-5">
            <spinner color="text-primary" />
          </div>
          <div v-else class="payment-list grid grid-cols-1 mt-5">
            <div class="flex flex-col w-full">
              <div class="overflow-x-scroll">
                <table class="table-auto min-h-full min-w-full">
                  <thead
                    class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                  >
                    <tr>
                      <th class="py-3 px-3">Client's Name</th>
                      <th class="py-3 px-3">Title</th>
                      <th class="py-3 px-3">Date Created</th>
                      <th class="py-3 px-3">Amount</th>
                      <th class="py-3 px-3">Amount Paid</th>
                      <th class="py-3 px-3">Amount Left</th>
                      <th class="py-3 px-3">Status</th>
                      <!-- <th class="py-3 px-3"></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="invoice in dataList"
                      @click="selectInvoice(invoice)"
                      :key="invoice._id"
                      class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                    >
                      <td class="px-3 py-4">
                        {{
                          invoice.client !== null
                            ? invoice.client.firstName
                            : ''
                        }}
                        {{
                          invoice.client !== null ? invoice.client.lastName : ''
                        }}
                      </td>
                      <td class="px-3 py-4">{{ invoice.title }}</td>
                      <td class="px-3 py-4">
                        {{ new Date(invoice.createdAt).toDateString() }}
                      </td>
                      <td class="px-3 py-4">
                        {{ numberWithCommas(invoice.amount) }}
                      </td>
                      <td class="px-3 py-4">
                        {{ numberWithCommas(invoice.totalAmountPaid) }}
                      </td>
                      <td class="px-3 py-4">
                        {{
                          numberWithCommas(
                            Number(invoice.amount) -
                              Number(invoice.totalAmountPaid)
                          )
                        }}
                      </td>
                      <td class="px-3 py-4" style="width: max-content">
                        <span
                          :class="
                            numberWithCommas(
                              Number(invoice.amount) -
                                Number(invoice.totalAmountPaid)
                            ) === 0
                              ? 'bg-green-500'
                              : 'bg-red-500'
                          "
                          class="font-semibold py-2 px-3 text-white rounded"
                          >{{ invoice.status }}</span
                        >
                      </td>
                      <!-- <td class="px-3 py-4" style="width: max-content">
                        <div class="relative inline-block text-left">
                          <span @click="toogleMenu(invoice)" class="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              focusable="false"
                              width="1.5em"
                              height="1.5em"
                              style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 32 32"
                            >
                              <circle cx="16" cy="8" r="2" fill="#626262" />
                              <circle cx="16" cy="16" r="2" fill="#626262" />
                              <circle cx="16" cy="24" r="2" fill="#626262" />
                            </svg>
                          </span>
                          <transition
                            enter-active-class="transition ease-out duration-100 transform"
                            enter-class="opacity-0 scale-95"
                            enter-to-class="opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75 transform"
                            leave-class="opacity-100 scale-100"
                            leave-to-class="opacity-0 scale-95"
                          >
                            <div
                              v-show="presentlyShowing === invoice._id"
                              class="z-10 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                            >
                              <div class="py-1" role="none">
                                <a
                                  href="#"
                                  class="flex items-center justify-between text-xyz-green px-4 py-2 text-sm hover:bg-xyz-gray-faded"
                                  role="menuitem"
                                >
                                  <span>Edit</span>
                                </a>
                                <a
                                  href="#"
                                  class="flex items-center justify-between px-4 text-xyz-gray-normal-text py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
                                  role="menuitem"
                                >
                                  <span>Delete</span>
                                </a>
                              </div>
                            </div>
                          </transition>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="mt-10 flex flex-wrap">
            <span
              v-for="l in meta.pages"
              :class="
                `p-2 ${
                  meta.offset / (l - 1) === (meta.limit || 0)
                    ? 'text-white bg-primary'
                    : 'text-black'
                } py-1 cursor-pointer rounded shadow m-2`
              "
              @click="getInvoices((l - 1) * meta.limit)"
              :key="l"
            >
              {{ l }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import Spinner from '../../components/Utils/Spinner'
import invoiceApi from '../../api/invoice'
import { numberWithCommas } from '../../utils/helpers'

export default {
  name: 'Payment',
  components: { PageTitle, PageSubtitle, Spinner },
  data: () => ({
    deleteInvoiceDialog: false,
    search: '',
    editingInvoice: false,
    numberWithCommas,
    editInvoiceDialog: false,
    deletingInvoice: false,
    loading: false,
    invoices: [],
    presentlyShowing: '',
    meta: {},
    editInvoice: false,
    selectedInvoice: {},
    selectedClient: {
      client: {}
    }
  }),
  created() {
    this.getInvoices()
  },
  computed: {
    dataList() {
      if (this.invoices.length === 0) return []
      return this.invoices.filter(c =>
        this.search
          .toLowerCase()
          .toString()
          .split(' ')
          .every(v => {
            if (c.client !== null) {
              const data =
                c.client?.firstName + ' ' + c.client?.lastName + ' ' + c.amount
              return data.toLowerCase().includes(v)
            } else {
              return c
            }
          })
      )
    }
  },
  methods: {
    async editAnInvoice() {
      this.editingInvoice = true
      const res = await invoiceApi.invoice().editInvoice({
        invoiceId: this.selectedClient._id,
        data: this.selectedClient
      })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.editingInvoice = false
        return
      }
      this.getInvoices(0)
      this.editingInvoice = false
      this.editInvoiceDialog = false
      this.editInvoice = false
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: 'Invoice editted successfully'
      })
    },
    async deleteInvoice(id) {
      this.deletingInvoice = true
      const res = await invoiceApi.invoice().deleteInvoice({ invoiceId: id })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingInvoice = false
        return
      }

      this.getInvoices(0)
      this.deletingInvoice = false
      this.deleteInvoiceDialog = false
      this.editInvoice = false
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: 'Invoice deleted successfully'
      })
    },
    toogleMenu(invoice) {
      if (this.presentlyShowing === invoice.id) {
        this.presentlyShowing = ''
        return
      }
      this.presentlyShowing = invoice.id
    },
    selectInvoice(client) {
      this.selectedClient = client
      this.editInvoice = true
      // this.$htmlToPaper('printDocs')
    },
    printInvoice() {
      this.$htmlToPaper('printDocs')
    },
    printWork() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printDocs').innerHTML

      // Get all stylesheets HTML
      let stylesHtml = ''
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      )

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
    },
    async getInvoices(offset) {
      this.loading = true
      const res = await invoiceApi.invoice().getAll({ offset })
      this.invoices = res.data.data
      this.meta = res.data.meta
      this.meta.pages = Math.ceil(this.meta.total / this.meta.limit)
      this.loading = false
    }
  }
}
</script>

<style></style>
